//https://jira.ncsoft.com/browse/MPLATFORM-2476 2022.09.21 일부 삭제

// RTK
import { appActions } from 'store/features/appSlice'
import { storeActions } from 'store/features/storeSlice'
import { lobbyActions } from 'store/features/lobbySlice'

// Utils
import history from 'config/historyConfig'
import store from 'config/storeConfig'
import { onReload, getQueryString } from '@purple/common/utils/commonUtility'
import {
  isValidURL,
  isLauncher,
  isIndex
} from '@purple/common/utils/checkUtility'
import { removeToken } from '@purple/common/utils/tokenUtility'

// Constants
import { TOKENKEY_TYPE } from 'constants/tokenTypes'

class nativeAsyncInstance {
  constructor() {
    const CefSharp = isLauncher && window['CefSharp']
    if (CefSharp) {
      const native = [
        'ngpGameServiceBindObject',
        'ngpInteractionBindObject',
        'ngpHomeBindObject',
        'ngpNotificationBindObject',
        'nativeGameCommander',
        'nativeStorage'
      ]
      native.map((command) => this.nativeBindObject(command))
    }
    window['NGP'] = Object.assign(this.commendExec, {})
  }

  nativeBindObject = (commend) => {
    const CefSharp = window['CefSharp'] || null
    const createCommand = CefSharp.BindObjectAsync(commend, commend)
    createCommand.then(() => {
      window[commend] = window[commend] || {}
    })
  }

  commendExec = {
    goTo: (url) => {
      console.log('---goTo호출', url)

      if (!url || url === window.location.pathname) return

      const isReplace = isIndex() || getQueryString('isReplace')

      const hrefLink = (link) => {
        if (isReplace) {
          window.location.replace(link)
        } else {
          // 퍼플 외 사이트는 href 이동
          window.location.href = link
        }
      }
      const pathLink = (pathname) => {
        if (isReplace) {
          history.replace(pathname)
        } else {
          history.push(pathname)
        }
      }

      if (isValidURL(url)) {
        const link = new URL(url)
        const currentOrigin = window.location.origin
        const isSameOrigin = link.origin === currentOrigin
        if (isSameOrigin) {
          // 같은 도메인이면 라우터 이동
          pathLink(link.pathname + link.search)
        } else {
          hrefLink(url)
        }
      } else {
        pathLink(url)
      }
    },
    logout: () => {
      console.log('logout 함수 호출')

      // lime 토큰 쿠키에서 제거
      removeToken(TOKENKEY_TYPE.LIME_JWT, {
        path: '/',
        domain: '.plaync.com'
      })

      // np 로그아웃 (서버에서 lime 로그아웃도 같이 처리)
      const xhr = new XMLHttpRequest()
      xhr.open('GET', '/logout?redirect=false')
      xhr.withCredentials = true
      xhr.send(null)
      console.log('logout ajax call완료')

      return true
    },
    onRefresh: () => {
      //런처 새로고침 버튼 눌렀을때
      console.log('새로고침 호출')
      onReload()
    },
    onFocusStatus: (bool) => {
      store.dispatch(appActions.setNativeFocusStatus(bool))
      console.log('===NGP.onFocusStatus: ', bool)
    },
    closePopup: (name) => {
      // 런처 팝업 닫힐 때 이름 전달
      store.dispatch(storeActions.setLauncherClosedPopupName(name))
      console.log('===NGP.closePopup: ', name)
    },
    closeTutorial: () => {
      console.log('===NGP.closeTutorial')
      store.dispatch(lobbyActions.setIsShowTutorial(false))
    }
  }
}

export default new nativeAsyncInstance()
