import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Textarea } from '@purple/design/react'
import { media } from 'assets/styles/media'
import { logd } from '@purple/common/logd'

// GTM
import GoogleAnalytics from 'config/analyticsConfig'

// Component
import LayerWrapper from 'components/Popup/components/common/LayerWrapper'
import LayerBody from 'components/Popup/components/common/LayerBody'
import LayerHeader from 'components/Popup/components/common/LayerHeader'
import { LayerFooter } from 'components/Popup/components/common/LayerFooter'
import StarRating from 'components/Store/ViewComponent/StarRating'
import { confirmPopup } from 'components/Popup'

// rtk
import {
  useEditGameReviewMutation,
  usePostGameReviewMutation
} from 'store/services/query/gameQuery'

// Utils
import { strLength } from '@purple/common/utils/commonUtility'
import { replaceNewLine } from 'utils/boardUtilsForStore'

const Top = styled.div`
  padding: 24px 0;
  text-align: center;
  ${media.tablet`
    padding-top: 40px;
  `}
`

const Title = styled.p`
  ${({ theme }) => theme.typography.subtitle5};
  color: ${({ theme }) => theme.color.glyphs200};
  margin-bottom: 16px;
  ${media.tablet`
    margin-bottom: 24px;
  `}
  ${media.phone`
    margin-bottom: 16px;
  `}
`

const TextWrap = styled.div`
  & > p {
    margin-top: 3px;
    text-align: right;
    ${({ theme }) => theme.typography.body4};
    color: ${({ theme }) => theme.color.glyphs300};
    & span {
      color: ${({ theme, disabled }) =>
        disabled ? theme.color.glyphs300 : theme.color.glyphs200};
    }
  }

  & textarea {
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background: none;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      border-radius: 5px;
      background-color: rgba(131, 137, 153, 0.3);
    }
  }
`

const InputBottom = styled.p`
  display: ${({ disabled }) => (disabled ? 'block' : 'none')};
  padding: 8px 20px;
  text-align: center;
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.color.glyphs200};
  white-space: pre-line;

  ${media.tablet`
    margin-top: auto;
  `}
`

function RegisterReviewPopup(props) {
  const {
    layerClose,
    myReview,
    gameInfo,
    goodsType,
    packageBundleId,
    goodsId,
    isFromRecommend = false
  } = props
  const { t } = useTranslation()

  const [text, setText] = useState(myReview ? myReview.content : '')
  const [rating, setRating] = useState(myReview ? myReview.score : 0)

  const [postGameReview] = usePostGameReviewMutation()
  const [editGameReview] = useEditGameReviewMutation()

  const editDisabled = useMemo(() => myReview && !myReview.is_show, [myReview])
  const maxLengh = 300

  const isReviewEventPeriod = useMemo(() => {
    if (gameInfo?.review_event_period) {
      const periodArr = gameInfo?.review_event_period?.split('~')
      const startDate = new Date(periodArr[0])
      const endDate = new Date(periodArr[1])
      const nowDate = new Date()
      return startDate && endDate && nowDate >= startDate && nowDate < endDate
    } else {
      return false
    }
  }, [gameInfo])

  const handleChangeInput = useCallback(
    (e) => {
      const text = e.target.value
      // 최대길이 체크
      if (strLength(text) > maxLengh) {
        return
      }
      setText(text?.trimStart())
    },
    [setText]
  )

  const handleClickRating = useCallback((value) => {
    setRating(value)
  }, [])

  // 등록
  const handleClickRegister = useCallback(async () => {
    GoogleAnalytics.sendGAEvent({
      event_category: '클릭',
      event_action: '게임 메인 리뷰 작성 - 버튼 클릭',
      event_label: '버튼 - 확인',
      event_button_code: 'pbw_games_main_review_popup',
      event_game_code: gameInfo.game_code
    })
    logd({
      type: 'pbw_games_main',
      name: 'pbw_games_main_review_popup',
      value: isFromRecommend,
      data: { gameCode: gameInfo.game_code }
    })

    if (rating < 1) {
      confirmPopup({
        msg: t('please select a rating'),
        type: 'alert'
      })
      return
    }

    // 연속된 빈줄바꿈 4줄이상일 경우 3줄로 치환
    const saveText = replaceNewLine({
      replaceString: '\n',
      replaceCount: 4,
      origin: text?.trim()
    })

    try {
      if (myReview) {
        // 수정
        await editGameReview({
          gameCode: gameInfo.game_code,
          reviewId: myReview.id,
          packageBundleId,
          goodsType,
          content: saveText,
          score: rating
        }).unwrap()
      } else {
        // 등록
        await postGameReview({
          gameCode: gameInfo.game_code,
          packageBundleId,
          goodsId,
          goodsType,
          content: saveText,
          score: rating
        }).unwrap()
      }

      layerClose()
    } catch (error) {}
  }, [
    myReview,
    goodsType,
    editGameReview,
    postGameReview,
    gameInfo,
    goodsId,
    packageBundleId,
    text,
    rating,
    layerClose,
    isFromRecommend,
    t
  ])

  return (
    <LayerWrapper>
      <LayerHeader
        title={myReview ? t('edit review') : t('write review')}
        onClickClose={layerClose}
      />

      <LayerBody
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Top>
          <Title>{gameInfo.name}</Title>
          <StarRating
            size={30}
            initialValue={rating}
            onClick={handleClickRating}
            disabled={editDisabled}
          />
        </Top>
        <TextWrap disabled={editDisabled}>
          <Textarea
            value={text}
            onChange={handleChangeInput}
            onResetInput={() => setText('')}
            height={'178px'}
            placeholder={
              isReviewEventPeriod && gameInfo?.review_event_placeholder
                ? gameInfo?.review_event_placeholder
                : t('please write review')
            }
            disabled={editDisabled}
          />
          <p>
            <span>{strLength(text)}</span>/
            {t('character length', { count: maxLengh })}
          </p>
        </TextWrap>
        <InputBottom
          disabled={editDisabled}
          dangerouslySetInnerHTML={{
            __html: t('this review has been made private')
          }}
        />
      </LayerBody>
      <LayerFooter
        leftButtonLabel={editDisabled ? false : t('cancel')}
        rightButtonLabel={t('confirm')}
        onClickLeft={layerClose}
        onClickRight={editDisabled ? layerClose : handleClickRegister}
      />
    </LayerWrapper>
  )
}

export default RegisterReviewPopup
