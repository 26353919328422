import i18n from 'i18next'
// Constants
import {
  ACTIVEMENU,
  LOBBY_LINK_TYPE,
  AUTO_PLAY_GAME,
  LMS_LANG_GROUP,
  TL_LANG_GROUP,
  LMS_CONTI_GROUP,
  BNS_COUNTRY_GROUP,
  AION_COUNTRY_GROUP,
  L2M_COUNTRY_GROUP,
  LIN_COUNTRY_GROUP,
  BNS_LANG_GROUP,
  AION_LANG_GROUP,
  LIN2_LANG_GROUP,
  LIN_LANG_GROUP,
  LIN_CONTI_GROUP,
  LIN2_CONTI_GROUP,
  AION_CONTI_GROUP,
  BNS_CONTI_GROUP,
  L2M_LANG_GROUP,
  L2M_CONTI_GROUP,
  LMS_COUNTRY_GROUP,
  LIN2_COUNTRY_GROUP,
  BNS2_COUNTRY_GROUP,
  BNS2_LANG_GROUP,
  BNS2_CONTI_GROUP,
  LOBBY_BG_KEY,
  LINW_LANG_GROUP,
  LINW_CONTI_GROUP,
  LINW_COUNTRY_GROUP,
  HOYEON_COUNTRY_GROUP,
  HOYEON_LANG_GROUP,
  HOYEON_CONTI_GROUP,
  TL_CONTI_GROUP,
  TL_COUNTRY_GROUP,
  LOBBY_LOGO_KEY,
  NLW_COUNTRY_GROUP,
  NLW_LANG_GROUP,
  BATTLECRUSH_COUNTRY_GROUP,
  BATTLECRUSH_LANG_GROUP,
  BATTLECRUSH_CONTI_GROUP
} from 'constants/homeTypes'
import {
  OFFICIALHOME_DOMAIN,
  OFFICIALHOME_PATH
} from 'constants/officalWebTypes'
import { GAME_CODE, GAME_CODE_UPPER } from '@purple/common/constants/gameTypes'
import {
  COUNTRY_TYPE,
  PRIMARY_LANG
} from '@purple/common/constants/locationTypes'
import { GO_TO_FROM_INFO_TYPE } from 'constants/nativeTypes'

// Utils
import history from 'config/historyConfig'
import {
  Config as config,
  configGameCodeGroup
} from '@purple/common/utils/globalUtility'
import { CountryCode, getLanguage } from '@purple/common/utils/locationUtility'
import {
  envGroup,
  onWindowOpen,
  openOutLink
} from '@purple/common/utils/commonUtility'
import { dayjs } from '@purple/common/utils/dayjsUtility'
import { currentTimeSotreInfo } from 'utils/storeUtility'
import { isLauncher, isPlaync } from '@purple/common/utils/checkUtility'
import { sizes } from 'assets/styles/media'
import { goToTALK } from 'native/nativeUtility'
import { match } from 'path-to-regexp'
import { HOME_PATH } from 'routes/home/path'

//캐릭터 디테일정보 조회 실패시 초기 데이터 셋팅 할 key값
export const getInitDetailInfoKey = (gameCode) => {
  switch (gameCode) {
    case ACTIVEMENU.L2M:
      return 'profile'
    default:
      return 'detail'
  }
}

//공홈 링크 생성에 필요한 망병 도메인 생성
const getOfficialWebDomain = (gameCode) => {
  let domain = ''

  switch (gameCode) {
    case GAME_CODE.LINW:
      domain = `${
        OFFICIALHOME_DOMAIN[envGroup][gameCode.toUpperCase()]
      }/${CountryCode().toLowerCase()}`
      break
    case GAME_CODE.BNS2:
      domain = `${
        OFFICIALHOME_DOMAIN[envGroup][gameCode.toUpperCase()]
      }/${BNS2_LANG_GROUP[findCountryInGroup(BNS2_CONTI_GROUP)]?.toLowerCase()}`
      break
    default:
      domain = OFFICIALHOME_DOMAIN[envGroup][gameCode.toUpperCase()]
  }

  return domain
}
// 프로필 링크 주소
export const officialMyProfile = (gameCode) => {
  const domain = getOfficialWebDomain(gameCode)
  const profilePath = OFFICIALHOME_PATH[gameCode.toUpperCase()]

  return `${domain}${profilePath}`
}

// CBT기간인지
export const isCBT = (gameCode) => {
  let { currentTime, diff } = currentTimeSotreInfo()

  currentTime = currentTime ?? new Date()
  diff = diff ?? dayjs(currentTime).diff(dayjs())

  const startDate = config[`promotion.${gameCode}.cbt.startDate`]
  const endDate = config[`promotion.${gameCode}.cbt.endDate`]
  const realCurrentTime = dayjs().add(diff, 'millisecond')

  const isExistDate = !!startDate && endDate
  const result =
    isExistDate &&
    realCurrentTime > dayjs(startDate) &&
    realCurrentTime <= dayjs(endDate)

  return result
}

const schemeHandlers = {
  moveSpotlight: () => {
    goToTALK({
      type: 'SPOTLIGHT',
      subType: 'LIST',
      param: {
        fromInfo: {
          type: GO_TO_FROM_INFO_TYPE.LAUNCHER_LOBBY
        }
      }
    })
  },
  viewSpotlight: ([link]) => {
    if (!link) return
    if (!isPlaync(link)) return

    const url = new URL(link)

    url.searchParams.append('inflowType', 'launcher')
    onWindowOpen({
      url: url.href,
      name: link,
      option:
        'width=1086,height=560,minWidth=812,minHeight=440,toolbar=0,menubar=0'
    })
  }
}

const convertToSchemeParam = (str) => {
  if (!str.startsWith('(')) {
    return undefined
  }

  return str.replace(/[\(\)]/g, '').split(',')
}

/**
 * 게임 코드 별로 링크 변경
 * @param gameCode
 * @param link
 * @returns {*}
 */
const convertLinkByGameCode = (gameCode, link, linkType) => {
  const shouldConvertProperties = {
    [GAME_CODE.LINW]: (link) => {
      return link.replaceAll('{country}', CountryCode().toLowerCase())
    },
    [GAME_CODE.L2MNCJ]: (link) => {
      return linkType === LOBBY_LINK_TYPE.LAUNCHER
        ? link.replaceAll('lineage2m.ncsoft.jp', 'jp-lineage2m.plaync.com')
        : link
    },
    [GAME_CODE.L2MNCT]: (link) => {
      return linkType === LOBBY_LINK_TYPE.LAUNCHER
        ? link.replaceAll('tw.ncsoft.com', 'tw.plaync.com')
        : link
    }
  }

  if (gameCode in shouldConvertProperties) {
    return shouldConvertProperties[gameCode](link)
  }

  return link
}

/**
 * 특정 링크로 오면 링크 변경
 * @param link
 * @returns {*|string}
 */
const convertLinkByLink = (link) => {
  const shouldConvertList = [
    { from: 'lounge.ncpurple.com', to: 'lounge.plaync.com' } // 라운지
  ]

  const convertIdx = shouldConvertList.findIndex((item) => {
    const reg = new RegExp(item.from, 'i')
    return reg.test(link)
  })
  if (convertIdx !== -1) {
    const newUrl = new URL(link)
    newUrl.hostname = newUrl.hostname.replace(
      shouldConvertList[convertIdx].from,
      shouldConvertList[convertIdx].to
    )
    return newUrl.href
  } else {
    return link
  }
}

const isSameOrigin = (link) => {
  const currentOrigin = window.location.origin
  const urlOrigin = new URL(link).origin
  return currentOrigin === urlOrigin
}

export const moveLinkByLinkType = ({ link, linkType, gameCode }) => {
  link = convertLinkByGameCode(gameCode, link, linkType)
  link = convertLinkByLink(link)

  switch (linkType) {
    case LOBBY_LINK_TYPE.BLANK:
      if (!link.match(/http:\/\/|https:\/\//g)) {
        console.error(`Invalid link = ${link} link Type = ${linkType}`)
        return
      }
      if (isSameOrigin(link)) {
        console.error(`Is same origin = ${link} link Type = ${linkType}`)
        return
      }
      openOutLink(link)
      break
    case LOBBY_LINK_TYPE.PURPLE_WEB:
    case LOBBY_LINK_TYPE.BOARD_WEB_VIEW:
    case LOBBY_LINK_TYPE.LAUNCHER:
      if (!link.match(/http:\/\/|https:\/\//g)) {
        history.push(link)
      } else {
        if (isSameOrigin(link)) {
          const url = new URL(link)
          history.push(url.pathname)
        } else {
          window.location.href = link
        }
      }
      break
    case LOBBY_LINK_TYPE.MINI_GAME:
      onWindowOpen({
        url: link,
        name: 'miniGame',
        option: 'width=480,height=750,toolbar=0,menubar=0',
        viewTitle: i18n.t('minigame'),
        viewMinWidth: 480,
        viewMinHeight: 750
      })
      break
    case LOBBY_LINK_TYPE.SCHEME:
      const scheme = link.match(/\blauncher:\/\/([\w]+)/)?.[0]
      const handlerKey = link.match(/\blauncher:\/\/([\w]+)/)?.[1]
      const stringifyParam = link.replace(scheme, '')

      const param = convertToSchemeParam(stringifyParam)
      if (!(handlerKey in schemeHandlers)) {
        console.error('not defined talk url handler!')
        return
      }
      schemeHandlers[handlerKey](param)
      break
    default:
      console.error(`Invalid linkType, link = ${link} linkType = ${linkType}`)
  }
}

export const isAutoPlayingGame = (game) => {
  return Object.keys(AUTO_PLAY_GAME).includes(game)
}

export const getLobbyMetaTags = (activeMenu) => {
  const lobbyMetaTags = {
    [GAME_CODE_UPPER.LMS]: 'lobby',
    [GAME_CODE_UPPER.L2M]: 'lobby',
    [GAME_CODE_UPPER.BNS2]: 'lobby',
    [GAME_CODE_UPPER.H3]: 'lobby',
    [GAME_CODE_UPPER.TRICKSTERM]: 'lobby',
    [GAME_CODE_UPPER.LINW]: 'lobby,' + CountryCode().toLowerCase(),
    [GAME_CODE_UPPER.TL]: 'lobby,' + CountryCode().toLowerCase(),
    [GAME_CODE_UPPER.LIN]: 'lobby',
    [GAME_CODE_UPPER.LIN2]: 'lobby',
    [GAME_CODE_UPPER.AION]: 'lobby',
    [GAME_CODE_UPPER.BNS]: 'lobby',
    [GAME_CODE_UPPER.LOVEBEAT]: 'lobby',
    [GAME_CODE_UPPER.HOYEON]: 'lobby,' + CountryCode().toLowerCase(),
    [GAME_CODE_UPPER.BATTLECRUSH]: 'lobby',
    [GAME_CODE_UPPER.NLW]:
      'lobby,' + (findCountryInGroup(NLW_COUNTRY_GROUP) || 'US').toLowerCase()
  }

  return lobbyMetaTags[activeMenu] ?? ''
}

export const getModifiedGameCodeByCountry = (gameCode) => {
  const country = CountryCode()
  if (country === 'KR') return gameCode

  switch (gameCode) {
    case GAME_CODE.BNS2:
      return gameCode + findCountryInGroup(BNS2_COUNTRY_GROUP)?.toLowerCase()
    case GAME_CODE.HOYEON:
      return gameCode + findCountryInGroup(HOYEON_COUNTRY_GROUP)?.toLowerCase()
    case GAME_CODE.TL:
      return gameCode + findCountryInGroup(TL_COUNTRY_GROUP)?.toLowerCase()
    case GAME_CODE.NLW:
      return (
        gameCode +
        (findCountryInGroup(NLW_COUNTRY_GROUP)?.toLowerCase() || 'us')
      )
    default:
      return gameCode
  }
}

export const getServiceAlias = (gameCode) => {
  let serviceAlias = configGameCodeGroup(gameCode)
  switch (serviceAlias) {
    case GAME_CODE.LMS:
      serviceAlias = 'lineagem'
      break
    case GAME_CODE.LIN:
      serviceAlias = 'lineage1'
      break
    case GAME_CODE.L2MNCT:
      serviceAlias = 'l2m_tw'
      break
    case GAME_CODE.L2MNCJ:
      serviceAlias = 'l2m_jp'
      break
    case GAME_CODE.BNS2:
      if (findCountryInGroup(BNS2_COUNTRY_GROUP) === 'TW') {
        serviceAlias = 'bns2_tw'
      } else if (findCountryInGroup(BNS2_COUNTRY_GROUP) === 'JP') {
        serviceAlias = 'bns2_jp'
      }
      break
    case GAME_CODE.NLW:
      serviceAlias = 'journey'
      break
    default:
  }

  return serviceAlias
}

export const isVisibleCharacterDetailLink = (gameCode) => {
  switch (gameCode) {
    case GAME_CODE.HOYEON:
    case GAME_CODE.LIN2NCT:
    case GAME_CODE.AIONNCT:
    case GAME_CODE.BNSNCT:
    case GAME_CODE.BNSNCJ:
    case GAME_CODE.AIONNCJ:
    case GAME_CODE.LMJ:
    case GAME_CODE.LINNCJ:
    case GAME_CODE.LIN2NCJ:
    case GAME_CODE.JANRYUMONJP:
    case GAME_CODE.AIONNCW:
    case GAME_CODE.BNSNCW:
    case GAME_CODE.LIN2NCW:
    case GAME_CODE.BATTLECRUSH:
      return false
    default:
      return true
  }
}

export const findCountryInGroup = (group) => {
  const country = CountryCode()
  for (const key in group) {
    if (group[key].includes(country)) return key
  }
}
export const findLanguageWithNCAPcGame = () => {
  const language = getLanguage()
  switch (language) {
    case COUNTRY_TYPE.FR:
    case COUNTRY_TYPE.DE:
      return language
    default:
      return COUNTRY_TYPE.US
  }
}

// 게임별 국가그룹
export function getCountryGroup(gameCode, country = CountryCode()) {
  switch (gameCode) {
    case GAME_CODE.LMS:
    case GAME_CODE.LMJ:
      return findCountryInGroup(LMS_COUNTRY_GROUP)
    case GAME_CODE.L2M:
    case GAME_CODE.L2MNCT:
    case GAME_CODE.L2MNCJ:
    case GAME_CODE.L2MNU:
    case GAME_CODE.L2MRU: //L2M의 경우 L2M_COUNTRY_GROUP 에서 kr/tw/jp/nu/ru중 1개로 반환
      return findCountryInGroup(L2M_COUNTRY_GROUP) /* 리니지2M */
    case GAME_CODE.LINW: //LW의 경우 국가 코드 그대로 반환
      return findCountryInGroup(LINW_COUNTRY_GROUP)
    case GAME_CODE.BNS2:
      return findCountryInGroup(BNS2_COUNTRY_GROUP)
    case GAME_CODE.HOYEON:
      return findCountryInGroup(HOYEON_COUNTRY_GROUP)
    case GAME_CODE.NLW:
      const countryGroup = findCountryInGroup(NLW_COUNTRY_GROUP)
        ? findCountryInGroup(NLW_COUNTRY_GROUP)
        : 'US'
      return countryGroup
    case GAME_CODE.TL:
      return findCountryInGroup(TL_COUNTRY_GROUP)
    case GAME_CODE.BATTLECRUSH:
      return findCountryInGroup(BATTLECRUSH_COUNTRY_GROUP)
    case GAME_CODE.LIN:
    case GAME_CODE.LINNCJ:
      return findCountryInGroup(LIN_COUNTRY_GROUP)
    case GAME_CODE.LIN2:
    case GAME_CODE.LIN2NCT:
    case GAME_CODE.LIN2NCJ:
    case GAME_CODE.LIN2NCW:
      return findCountryInGroup(LIN2_COUNTRY_GROUP)
    case GAME_CODE.AION:
    case GAME_CODE.AIONNCT:
    case GAME_CODE.AIONNCJ:
    case GAME_CODE.AIONNCW:
      return findCountryInGroup(AION_COUNTRY_GROUP)
    case GAME_CODE.BNS:
    case GAME_CODE.BNSNCT:
    case GAME_CODE.BNSNCJ:
    case GAME_CODE.BNSNCW:
      return findCountryInGroup(BNS_COUNTRY_GROUP)
    case GAME_CODE.JANRYUMONJP:
      return COUNTRY_TYPE.JP
    default:
      return COUNTRY_TYPE.KR
  }
}

//게임별 대표 언어 - 콘티용
export function getPrimaryLang(gameCode, country = CountryCode()) {
  switch (gameCode) {
    case GAME_CODE.LMS:
    case GAME_CODE.LMJ:
      return LMS_LANG_GROUP[findCountryInGroup(LMS_CONTI_GROUP)]
    case GAME_CODE.L2M:
    case GAME_CODE.L2MNCT:
    case GAME_CODE.L2MNCJ:
    case GAME_CODE.L2MNU:
    case GAME_CODE.L2MRU: //L2M의 경우 L2M_COUNTRY_GROUP 에서 kr/tw/jp/nu/ru중 1개로 반환
      return L2M_LANG_GROUP[findCountryInGroup(L2M_CONTI_GROUP)] // 리니지 2m
    case GAME_CODE.TL:
      return TL_LANG_GROUP[findCountryInGroup(TL_CONTI_GROUP)] /* TL */
    case GAME_CODE.LINW:
      return LINW_LANG_GROUP[findCountryInGroup(LINW_CONTI_GROUP)] /* 리니지w */
    case GAME_CODE.BNS2:
      return BNS2_LANG_GROUP[findCountryInGroup(BNS2_CONTI_GROUP)]
    case GAME_CODE.HOYEON:
      return HOYEON_LANG_GROUP[findCountryInGroup(HOYEON_CONTI_GROUP)]
    case GAME_CODE.NLW:
      const countryGroup = findCountryInGroup(NLW_COUNTRY_GROUP)
        ? findCountryInGroup(NLW_COUNTRY_GROUP)
        : 'US'
      return NLW_LANG_GROUP[countryGroup]
    case GAME_CODE.BATTLECRUSH:
      return BATTLECRUSH_LANG_GROUP[findCountryInGroup(BATTLECRUSH_CONTI_GROUP)]
    case GAME_CODE.LIN:
    case GAME_CODE.LINNCJ:
      return LIN_LANG_GROUP[findCountryInGroup(LIN_CONTI_GROUP)]
    case GAME_CODE.LIN2:
    case GAME_CODE.LIN2NCT:
    case GAME_CODE.LIN2NCJ:
      return LIN2_LANG_GROUP[findCountryInGroup(LIN2_CONTI_GROUP)]
    case GAME_CODE.LIN2NCW:
      return LIN2_LANG_GROUP[findLanguageWithNCAPcGame()]
    case GAME_CODE.AION:
    case GAME_CODE.AIONNCT:
    case GAME_CODE.AIONNCJ:
      return AION_LANG_GROUP[findCountryInGroup(AION_CONTI_GROUP)]
    case GAME_CODE.AIONNCW:
      return AION_LANG_GROUP[findLanguageWithNCAPcGame()]
    case GAME_CODE.BNS:
    case GAME_CODE.BNSNCT:
    case GAME_CODE.BNSNCJ:
      return BNS_LANG_GROUP[findCountryInGroup(BNS_CONTI_GROUP)]
    case GAME_CODE.BNSNCW:
      return BNS_LANG_GROUP[findLanguageWithNCAPcGame()]
    case GAME_CODE.JANRYUMONJP:
      return PRIMARY_LANG.JP
    default:
      return PRIMARY_LANG.KR
  }
}

// 게임별 콘티 alias
export function getContiAlias(alias, gameCode) {
  if (!alias) return
  if (!gameCode) return alias

  const aliasArray = alias.split(',').map((item) => item.trim())

  return aliasArray
    .map((item) => `${getCountryGroup(gameCode)}_${item}`)
    .toString()
}

export function getLobbyBgOrLogo(gameCode, type = 'bg') {
  const modifiedGameCode = getModifiedGameCodeByCountry(gameCode)
  const key = type === 'bg' ? LOBBY_BG_KEY : LOBBY_LOGO_KEY

  switch (modifiedGameCode) {
    // NCJ Lobby BG
    case GAME_CODE.L2MNCJ:
    case GAME_CODE.LMJ:
    case GAME_CODE.JANRYUMONJP:
    case GAME_CODE.AIONNCJ:
    case GAME_CODE.BNSNCJ:
    case GAME_CODE.LIN2NCJ:
    case GAME_CODE.LINNCJ:
    case 'hoyeonjp':
    case 'bns2jp':
      return config?.[key['NCJ']]?.[modifiedGameCode]

    // NCT Lobby BG
    case GAME_CODE.L2MNCT:
    case GAME_CODE.AIONNCT:
    case GAME_CODE.BNSNCT:
    case GAME_CODE.LIN2NCT:
    case 'hoyeontw':
    case 'bns2tw':
      return config?.[key['NCT']]?.[modifiedGameCode]

    case GAME_CODE.LIN2NCW:
    case GAME_CODE.AIONNCW:
    case GAME_CODE.BNSNCW:
      return config?.[key['NCW']]?.[modifiedGameCode]
    // default NCK
    default:
      return config?.[key['NCK']]?.[modifiedGameCode]
  }
}

export function is4kSize() {
  return window.innerWidth > sizes['4k']
}

export function isJumboSize() {
  return window.innerWidth > sizes.jumbo
}

export function isMaxSize() {
  return window.innerWidth > sizes.max
}

export function isGiantSize() {
  return window.innerWidth > sizes.giant
}

export function isDesktopSize() {
  return window.innerWidth > sizes.desktop
}

export function isTabletSize() {
  return window.innerWidth > sizes.tablet
}

export function isAllPropertiesDefined(obj) {
  return Object.values(obj).every((x) => x !== null && x !== undefined)
}

export const isVisibleCharacterDetail = (gameCode) => {
  switch (gameCode) {
    case GAME_CODE.LIN:
    case GAME_CODE.LINNCJ:
    case GAME_CODE.LOVEBEAT:
    case GAME_CODE.JANRYUMONJP:
    case GAME_CODE.BATTLECRUSH:
      return false
    default:
      return true
  }
}

//로비인지 확인
export const isLobby = () => {
  return /(lobby|promotion|publish)/i.test(window.location.pathname)
}

export const isHomeLobby = () => {
  return !!match(HOME_PATH.LOBBY)(window.location.pathname)
}
